import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        object: {
            name: null,
            file: null,
        },
        texture: {
            id: null,
            name: null,
            file: null,
        },
        option: {
            name: null,
            code: null,
        },
        product: {
            name: null,
            id: null,
        },
        cart: {
            cushion_hand: { name: "none" },
            cushion_seat: { name: "none" },
            pillow: { name: "none" },
            curtain: { name: "none" },
        },
    },
    mutations: {
        onChangeObject(state, object) {
            state.object = {
                name: object.name,
                file: object.file,
            };
        },
        onChangeMaterial(state, material) {
            state.texture = {
                id: material.id,
                name: material.name,
                file: material.file,
            };
        },
        onChangeOption(state, option) {
            state.option = {
                name: option.name,
                code: option.code,
            };
        },
        onChangeProduct(state, product) {
            state.product = {
                name: product.name,
                id: product.id,
            };
        },
        onChangeCart(state, data) {
            state.cart[data.option] = data.mat;
        },
    },
    actions: {},
    modules: {},
    getters: {
        cart(state) {
            return state.cart;
        },
    },
});
